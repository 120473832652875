import { Path, Svg } from "@swan-io/lake/src/components/Svg";

const HEIGHT = 11;
const WIDTH = 41;

type Props = {
  height?: number;
};

export const SepaLogo = ({ height = HEIGHT }: Props) => (
  <Svg viewBox="0 0 41 11" height={height} width={(WIDTH / HEIGHT) * height}>
    <Path
      d="M20.457 2.843a3.998 3.998 0 00-3.06-1.422 4.01 4.01 0 00-3.47 2.008l-.044.075H20.1l-.7 1.498h-5.993l-.004.045a4.085 4.085 0 00.024 1.004l.006.042h5.459l-.699 1.5h-4.201l.051.077a4.007 4.007 0 003.354 1.817 3.995 3.995 0 002.925-1.27v1.803c-.89.587-1.962.93-3.117.93-2.31 0-4.291-1.373-5.138-3.327l-.013-.03h-1.932l.699-1.5h.839l-.006-.055a5.357 5.357 0 01-.007-.982l.004-.054h-1.6l.699-1.498h1.264l.013-.031C12.852 1.467 14.858.05 17.205.05c1.494 0 2.85.575 3.851 1.51l-.599 1.283z"
      strokeWidth={0.1}
      fill="#f2f536"
      stroke="#372bbc"
    />

    <Path
      d="M9.317 3.498H6.562c0-.452-.053-.756-.159-.91-.164-.228-.616-.343-1.356-.343-.72 0-1.196.066-1.428.198-.233.132-.35.42-.35.861 0 .4.104.665.31.791.148.09.344.143.587.158l.556.04c1.19.078 1.934.134 2.23.166.942.095 1.624.345 2.048.75.333.317.532.731.595 1.242.037.305.055.651.055 1.035 0 .886-.084 1.534-.253 1.945-.307.755-.996 1.23-2.068 1.431-.449.085-1.136.127-2.06.127-1.542 0-2.617-.092-3.224-.277C1.3 10.486.809 10.029.571 9.34.44 8.955.373 8.315.373 7.42h2.755v.23c0 .477.137.784.411.92.19.095.42.145.689.15H5.24c.517 0 .847-.026.989-.079.253-.1.42-.26.499-.482.042-.136.063-.313.063-.53 0-.483-.177-.778-.53-.884-.132-.041-.746-.1-1.842-.173-.88-.063-1.492-.124-1.834-.182-.902-.169-1.503-.516-1.803-1.043C.518 4.9.387 4.223.387 3.317c0-.69.071-1.242.213-1.658.143-.416.373-.732.69-.948C1.753.379 2.347.184 3.07.126c.602-.052 1.27-.08 2.004-.08 1.156 0 1.98.067 2.47.2 1.2.321 1.798 1.223 1.798 2.705 0 .122-.009.304-.026.547zM21.428 11V0h5.516c.756 0 1.334.062 1.73.185.907.284 1.516.866 1.828 1.746.16.462.241 1.157.241 2.085 0 1.115-.089 1.917-.266 2.406-.354.965-1.082 1.521-2.181 1.665-.13.022-.68.041-1.651.057l-.491.016h-1.765V11h-2.961zm2.96-5.392h1.846c.585-.02.941-.066 1.07-.136.174-.096.292-.29.35-.578.038-.192.056-.478.056-.858 0-.465-.037-.81-.112-1.035-.106-.316-.36-.508-.765-.578a5.993 5.993 0 00-.574-.016h-1.87v3.201zm12.675 3.491h-3.958L32.573 11H29.51l3.305-11h4.466l3.346 11h-2.999l-.565-1.901zm-.588-2.143l-1.387-4.768-1.346 4.768h2.733z"
      fill="#372bbc"
    />
  </Svg>
);
